<template>
    <div class="vIfModalContainer" ref="overlay" id="vIfModal">
       <div class="vIfModalOuterWrapper" @click="checkClose($event)" ref="overlay" id="modalDoc">
            <div class="vIfModalInnerWrapper address-book-modal" id="vIfModalContent">
                <div class="vIfModalClose" @click="$emit('close')" v-if="hasCloseButton">
                    <span class="cursor-pointer"><span class="material-icons-outlined">close</span></span>
                </div>
                <slot></slot>
            </div>
       </div>
    </div>
</template>

<script>
    /**
     * This modal is intended for situations where you need a modal, but require a v-if so that it doesn't immediately load.
     */
    export default {
        props: {
            hasCloseButton: {
                default: true
            }
        },
        methods: {
            checkClose(e) {
                if (e.target == this.$refs['overlay']) {
                    this.$emit('close');
                }
            }
        },
        mounted(){
            setTimeout(() => {
                var specifiedElement = document.getElementById("vIfModalContent");
            
                //I'm using "click" but it works with any event
                document.addEventListener("click", event => {
                    var isClickInside = specifiedElement.contains(event.target);
                    if (!isClickInside) {
                        this.$emit("close");
                    }
                });
            }, 300);
        }
    };
</script>