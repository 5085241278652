<template>
    <div class="SignConvertPdf align-center dark-text weight-600">
        <span class="material-icons-outlined purple">draw</span> Signing your form and converting to pdf...
    </div>
</template>

<script>
    export default {
        name: 'SignConvertPdfModal'
    }
</script>