<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase() + '-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id"><FormClient :client_id="client_id" @getClient="getClient" /><br /></div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button
                    type="button"
                    @click="signature_ids = []"
                    v-if="signature_ids.length && original_signature_ids.length"
                >
                    Edit & Create Addendum
                </button>
                <button
                    v-if="original_signature_ids.length && !signature_ids.length"
                    class="align-right"
                    type="button"
                    @click="signature_ids = original_signature_ids"
                >
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->
                <div class="dark-text header-w-bg">Adult 65D-30 Outpatient ASAM Level I</div>
                <h3>[3] Discharge / Transfer</h3>
                <Radios
                    class="block radios asam-radios"
                    :name="'discharge_transfer'"
                    :options="['Discharge', 'Transfer']"
                    v-model="rec.dischargeTransfer"
                    label="Please select if this is a Transfer or a Discharge:"
                    input-class="block"
                    label-class="inline-block"
                />
                <div v-show="rec.dischargeTransfer === 'Transfer'">
                    <label for="levelOfCare">Level of Care: </label>
                    <input type="text" v-model="rec.levelOfCare" id="levelOfCare" />
                </div>
                <p>
                    Check all items in each dimension that apply to the client. Place a check in the appropriate box
                    that indicates validation of lack of validation for discharge or transfer from this level of care.
                </p>

                <div class="avoid-break">
                    <h3>Dimension 1: Acute Intoxication and/or Withdrawal Potential</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_one"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. Client is free from intoxication or withdrawal symptoms/risks; or',
                            'b. The client exhibits symptoms of severe intoxication and/or withdrawal, which cannot be safely managed at this level of care.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 2: Biomedical conditions and complications</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_two"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. The client’s biomedical conditions, if any, have diminished or stabilized to the extent they can be managed through outpatient appointments at the client’s discretion, and the client does not meet any of the continued stay criteria in this or another dimension that indicates the need for further treatment in ASAM Level I; or',
                            'b. The client has a biomedical condition that is interfering with addiction treatment and that requires treatment in another setting.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 3: Emotional, behavioral or cognitive conditions and complications</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_three"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. The client’s emotional, behavioral or cognitive conditions, if any, have diminished or stabilized to the extent they can be managed through outpatient appointments at the client’s discretion, and the client does not meet any of the continued stay criteria in this or another dimension that indicates the need for further treatment in ASAM Level I; or',
                            'b. The client has an emotional, behavioral or cognitive condition that is interfering with treatment and that requires additional treatment in another setting.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 4: Readiness to change</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_four"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. The client’s awareness and acceptance of his/her addiction problem and commitment to recovery is sufficient to expect maintenance of a self-directed recovery plan, based on the following evidence: 1) The client recognizes the severity of the substance abuse problem; 2) The client has an understanding of the self-defeating relationship with substances; 3) The client is applying the skills necessary to maintain sobriety in a mutual self-help group and/or with post-treatment support care; and 4) The client does not meet any of the continued stay criteria in this or another dimension that indicates the need for further treatment in ASAM Level I; or',
                            'b. The client consistently has failed to achieve essential treatment objectives despite revisions to the treatment plan, to an extent that no further progress is likely to occur.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 5: Relapse/Continued Use Potential</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_five"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            ' a. The client’s therapeutic gains in addressing craving and relapse issues have been internalized and integrated so the client does not meet any of the ASAM Level I continued stay criteria in this or another dimension that indicates the need for further treatment in ASAM Level I; or',
                            'b. The client is experiencing a worsening of drug-seeking behaviors or craving, requiring treatment in a more intensive level of care.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 6: Recovery environment</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_six"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. The client’s social system and significant others are supportive of recovery to an extent that the client can follow a self-directed treatment plan without substantial risk of relapse/continued use and the client does not meet any of the continued service criteria in this or another dimension that indicates the need for further treatment at ASAM Level I; or',
                            'b. The client is functioning adequately in assessed life task areas of work, social functioning or primary relationships and does not meet any of the continued service criteria in this or another dimension that indicates the need for further treatment at ASAM Level I; or',
                            'c. The client’s social system remains non-supportive or has deteriorated. The client is having difficulty coping with this environment and is at substantial risk of relapse and requires placement in a more intensive level of care'
                        ]"
                    />
                </div>
                <ExpandableTextArea
                    class="avoid-break block top-30"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="recommendations"
                    v-model="rec.recommendations"
                    label="Recommendations/Notes:"
                    :limit=4000
                    inputStyle="min-height:20px;"
                />

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button
                        v-if="original_signature_ids.length && !signature_ids.length"
                        class="align-right inline-block secondary right-15"
                        type="button"
                        @click="signature_ids = original_signature_ids"
                    >
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />
                </div>
                <FormSignature
                    :user_id="user_id"
                    :signature_ids="signature_ids"
                    ref="form_signatures"
                    @getSignatures="getSignatures"
                    v-if="signature_ids.length > 0"
                />
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    export default {
        name: 'OpDischargeTransferASAM',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                this.loading = 0;
            }
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
